import { navigate, PageProps } from "gatsby"
import React, { ReactElement, useState } from "react"
import MediaQuery from "react-responsive"
import { v4 as uuid } from "uuid"
import { Parking } from "../__generated__/proto/itech/motorist/pksha/v1/parking"
import { Transaction as ProtoTransaction } from "../__generated__/proto/itech/motorist/pksha/v1/transaction"
import {
  Layout,
  SEO,
  ParkingReservationInfoTable,
  ParkingReservationForm,
} from "../components"
import { Dialog } from "../components/common/dialog"
import * as config from "../config"
import { Transaction } from "../domain/models"
import { AuthError, ReservationService } from "../domain/services"
import { ReserveUseCase } from "../domain/use-cases"
import AuthGuard from "../middleware/AuthGuard"
import * as styles from "./parking-reservation.module.scss"

const reservationService = new ReservationService()
const reserveUseCase = new ReserveUseCase(reservationService)

interface PageContext {
  parking: Parking
}

const ParkingReservationPage = (
  props: PageProps<void, PageContext>
): ReactElement => {
  const currentParking = props.pageContext.parking

  const [isNotificationOpened, setIsNotificationOpened] = useState(true)
  const [reservedTime, setReservedTime] = useState<Date>()
  const [reservationError, setReservationError] = useState<string>()

  const handleSubmit = async (
    licenseNumber: string,
    spaceNumber: string,
    requireHandicappedSpace: boolean
  ) => {
    try {
      const result = await reserveUseCase.call({
        requestId: uuid(),
        parkingSpaceName: `${currentParking.name}/spaces/${spaceNumber}`,
        licenseNumber: licenseNumber,
        requireHandicappedSpace: requireHandicappedSpace,
      })

      if (result.transaction == null) {
        setReservationError(
          "車室に空きがなく予約することができませんでした。\n駐車場検索画面から空き状況を確認いただき、再度ご予約ください。"
        )
        return
      }

      const transaction = Transaction.fromProto(
        result.transaction as ProtoTransaction
      )
      if (transaction.reserved?.createTime != null) {
        setReservedTime(new Date(transaction.reserved.createTime))
      }
    } catch (e) {
      if (e instanceof AuthError) {
        await navigate("/signin", { replace: true })
      }
    }
  }

  return (
    <AuthGuard>
      <Layout>
        <SEO
          title={currentParking.displayName}
          description={
            currentParking &&
            `${currentParking.displayName}（${
              currentParking.fullAddress
            }）の予約情報です。車室数は${
              currentParking.availability?.totalSpaceCount ?? "-"
            }台です。`
          }
          lang="ja"
        />
        <div className={styles.mainContainer}>
          <MediaQuery minWidth={config.RESPONSIVE_BREAKPOINT}>
            <button
              className={styles.backToTopButton}
              onClick={() => navigate(`/${currentParking.name}` ?? "/")}
            >
              駐車場詳細へもどる
            </button>
          </MediaQuery>
          <div className={styles.reservationContainer}>
            <h2>予約手続き</h2>
            <div className={styles.alertSection}>
              【ご注意】
              <p>
                ※予約開始から入庫までの時間も駐車料金が発生します（予約料金）。予約料金には最大料金が適用されませんのでご注意ください。
              </p>
              <p className={styles.noticeText}>
                ※「コインパ予約」は、駐車場の車室を完全に確保するものではございません。他のお客様が間違って入庫してしまう場合もございますのでご了承ください。この場合、予約料金は間違って入庫されたお客様にご請求します。
              </p>
            </div>
            <ParkingReservationInfoTable parking={currentParking} />
            <ParkingReservationForm onSubmit={handleSubmit} />
          </div>
        </div>

        <Dialog
          open={isNotificationOpened}
          title="コインパ予約における注意事項"
          confirmText="OK"
          onClose={() => setIsNotificationOpened(false)}
        >
          <div className={styles.dialogDescription}>
            <ul>
              <li>
                「コインパ予約」は、駐車場の車室を完全に確保するものではございません。他のお客様が間違って入庫してしまう場合もございますので、ご理解の上でご利用ください。
              </li>
              <li>
                予約から入庫あるいはキャンセルまでの時間も料金(予約料金)が発生します。予約料金には、最大料金が適用されませんのでご注意ください。
              </li>
            </ul>
          </div>
        </Dialog>

        <Dialog
          open={reservedTime != null}
          title="ご予約が完了しました"
          confirmText="OK"
          onClose={async () => await navigate("/reservation-detail")}
        >
          <div className={styles.dialogDescription}>
            <ul>
              <li>
                予約した時点から料金が発生します。予約開始から入庫までは最大料金が適用されません。
              </li>
              <li>以下の予約開始時刻より2時間まで予約しました。</li>
            </ul>
            <p className={styles.reservationTimeTitle}>ご予約開始時刻</p>
            <p>
              {new Intl.DateTimeFormat("ja-JP", {
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }).format(reservedTime)}
            </p>
          </div>
        </Dialog>

        <Dialog
          open={reservationError != null}
          title="予約エラー"
          confirmText="OK"
          onClose={() => setReservationError(undefined)}
        >
          <div>
            {reservationError?.split("\n").map((line, index) => {
              return <p key={index}>{line}</p>
            })}
          </div>
        </Dialog>
      </Layout>
    </AuthGuard>
  )
}

export default ParkingReservationPage
